@tailwind base;
@tailwind components;
@tailwind utilities;

.temp {
    
}

body {
    @apply font-[Consolas];
    @apply bg-background;
    
}
li {
    @apply px-4;
    @apply cursor-pointer;
}

.secondary-title {
    @apply font-bold text-5xl h-2 bg-head1 rounded-full mb-12 pt-2;
    width: 6rem;
}

.secFelir {
    width: 29rem !important;
}

p {
    font-size: larger;
}

.projektek {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    padding-bottom: 20px;
}

.mosott {
    background-color: rgba(0, 0, 0, 0.568);
    border-radius: 5px;
    backdrop-filter: blur(10px);
    border-left: 3px rgba(0, 225, 255, 0.226) solid;
    padding-left: 3px;
    text-align: center;
    @apply border-l-cyan-300
}

.gomb {
    background-color: rgba(0, 225, 255, 0.192);
    backdrop-filter: blur(10px);
    border: 3px rgba(0, 225, 255, 0.226) solid;
    @apply border-head1 bg-none text-center rounded-lg px-4 py-3 m-2 text-gray-200 font-bold text-lg;
}
.UresGomb {
    border: 3px rgba(0, 225, 255, 0.226) solid;
    @apply opacity-0 bg-none text-center rounded-lg px-4 py-3 m-2 text-gray-200 font-bold text-lg;
    pointer-events: none;
}

.kikapcsolt {
    pointer-events: none;
}
/*
.gomb:hover {
    background-color: rgba(0, 225, 255, 0.801);
    backdrop-filter: blur(10px);
    border: 3px rgba(0, 225, 255, 0.226) solid;
    @apply border-cyan-800 bg-none text-center rounded-lg px-4 py-3 m-2 text-gray-200 font-bold text-lg;
} */

.doboz:hover {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.elvalaszto {
    @apply bg-koszones h-1 mx-auto rounded-sm
}


@media screen and (max-width: 350px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 1rem 0;
        @apply bg-background;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 17rem;
        padding-bottom: 17rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 20rem;
        padding-bottom: 10rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 0rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 0rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }
@media screen and (min-width: 350px) and (max-width: 400px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 2rem 0;
        @apply bg-background;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 10rem;
        padding-bottom: 17rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 20rem;
        padding-bottom: 5rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 0rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 0rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }

@media screen and (min-width: 400px) and (max-width: 700px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 3rem 0;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 10rem;
        padding-bottom: 17rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 20rem;
        padding-bottom: 5rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 0rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 0rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }

  @media screen and (min-width: 700px) and (max-width: 900px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 8rem 0;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 20rem;
        padding-bottom: 25rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 23rem;
        padding-bottom: 28rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 0rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 0rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }
@media screen and (min-width: 900px) and (max-width: 1600px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 10rem 0;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 40rem;
        padding-bottom: 17rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 20rem;
        padding-bottom: 5rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 15rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 10rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }



@media screen and (min-width: 1600px) {
    /*A menu alatti*/
    .szunet:nth-child(2) {
        padding: 10rem 0;
    }
    /*Rólam*/
    .szunet:nth-child(5) {
        padding-top: 40rem;
        padding-bottom: 17rem;
    }
    /*Ezennel bemutatom a T42.hu...*/
    .szunet:nth-child(6) {
        padding-top: 20rem;
        padding-bottom: 5rem;
    }
    /* Eredményeim */
    .szunet:nth-child(7) {
        padding-top: 30rem;
        padding-bottom: 15rem;
    }
    /* Ismereteim */
    .szunet:nth-child(8) {
        padding-top: 15rem;
        padding-bottom: 0;
    }
    /* Munkáim */
    .szunet:nth-child(9) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    /* Kapcsolat */
    .szunet:nth-child(10) {
        padding-top: 5rem;
        padding-bottom: 0;
    }
    .elvalaszto {
        display: none !important;
    }
  }

.nobackgr {
    @apply bg-mainColor;
}



body::-webkit-scrollbar {
    width: 6px;
  }
  body:hover::-webkit-scrollbar {
    width: 6px;
  }
  body::-webkit-scrollbar-track {
    background-color: transparent;
  }
  body:hover::-webkit-scrollbar-thumb {
    background-color: #0798c446;
    border-radius: 9999px;
  }