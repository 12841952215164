.neon-link {
    position: relative;
    display: inline-block;
    color: #03e9f4;
    text-decoration: none;
    overflow: hidden;
    transition: 75ms;
    @apply border-neon;
}

.neon-link:hover {
    border: none;
    @apply bg-neon;
    color: #050801;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 200px #03e9f4;
}

.neon-link-span {
    position: absolute;
    display: block;
}

.neon-link span:nth-child(1){
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#03e9f4);
    animation: NEONanimate1 1s linear infinite;
}
@keyframes NEONanimate1 {
    0%{
        left: -100%;
    }
    50%,100% {
        left: 100%;
    }
}
.neon-link span:nth-child(2){
    top: -100%;
    right: 0px;
    width: 3px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#03e9f4);
    animation: NEONanimate2 1s linear infinite;
    animation-delay: 0.25s;
}
@keyframes NEONanimate2 {
    0%{
        top: -100%;
    }
    50%,100% {
        top: 100%;
    }
}
.neon-link span:nth-child(3){
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#03e9f4);
    animation: NEONanimate3 1s linear infinite;
    animation-delay: 0.5s;
}
@keyframes NEONanimate3 {
    0%{
        right: -100%;
    }
    50%,100% {
        right: 100%;
    }
}
.neon-link span:nth-child(4){
    bottom: -100%;
    left: 0px;
    width: 2px;
    height: 100%;
    background: linear-gradient(3600deg,transparent,#03e9f4);
    animation: NEONanimate4 1s linear infinite;
    animation-delay: 0.75s;
}
@keyframes NEONanimate4 {
    0%{
        bottom: -100%;
    }
    50%,100% {
        bottom: 100%;
    }
}

.kek-link:hover {
    border: none;
    @apply border-neon bg-neon;
    color: #050801;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 200px #03e9f4;
    transition: 75ms;
}

.color-white {
    color: white;
}

.alplink {
    @apply border-zold text-zold;
}

.alplink:visited {
    @apply text-zold;
}

.alplink:hover {
    background-color: #03f40f;
    border: none;
    color: #050801;
    box-shadow: 0 0 5px #03f40f,
                0 0 25px #03f40f,
                0 0 50px #03f40f,
                0 0 200px #03f40f;
    -webkit-box-reflect: below 0px linear-grardient (transparent,#0002);
    transition: 75ms;
}

.a-gomb {
    color: white;
    background-color: tomato;
    border-color: rgba(255, 99, 71, 0.719);
}

.a-gomb:visited {
    color: white;
}

.a-gomb:hover {
    background-color: #f4e403;
    border-color: #f4a003;
    color: #ffffff;
    box-shadow: 0 0 5px #f4a003,
                0 0 25px #f4a003,
                0 0 50px #f4a003,
                0 0 200px #f4a003;
    -webkit-box-reflect: below 0px linear-grardient (transparent,#0002);
    transition: 75ms;
}

.menu-link {
    position: relative;
    
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
}

.menu-link:hover {
    
}

.menu-link-span {
    position: absolute;
    display: block;
}

.menu-link:hover span:nth-child(1){
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#03e9f4);
    animation: MeNUanimate1 1s linear;
}

.menu-link:active span:nth-child(1){
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#03e9f4);
    animation: MeNUanimate1 0.5s linear infinite;
}

@keyframes MeNUanimate1 {
    0%{
        right: 100%;
    }
    50%,100% {
        right: -100%;
    }
}


